<template>
  <div class="page-rooter-fff">
    <headBar
      ref="header"
      title="反馈记录"
      left-arrow
      @click-left="newAppBack"
    />
    <van-pull-refresh
      class="page-refresh"
      v-model="refreshing"
      @refresh="getDataList(false)"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多记录了~"
        @load="getDataList(true)"
        :immediate-check="false"
        class="list"
        v-if="list.length"
      >
        <div
          @click="jumpDetail(item)"
          v-for="(item, index) in list"
          :key="index"
          class="item"
        >
          <div class="row-between-center">
            <div class="date-str">{{ item.createDate }}</div>
            <span :class="[item.resolveStatus == 1 ? '' : 'red' , 'status', 'f24', 'fw6']">{{ item.resolveStatus == 1 ? "已完结" : "待解决" }}</span>
          </div>
          <div class="text row-start-center">
            <h3>{{ item.feedbackClassifyStr }}</h3>
            <p class="row-center-center">
              <img
                src="https://img.chaolu.com.cn/MINI/feedback/triangle.png"
                alt=""
              />
            </p>
            <h4 class="ellipsis">{{ item.content }}</h4>
          </div>
        </div>
      </van-list>
      <div v-else class="empty col-center-center">
        <img
          src="https://img.chaolu.com.cn/ACT/give-equity-card/empty.png"
          alt=""
        />
        <p>暂无反馈记录</p>
      </div>
    </van-pull-refresh>

    <van-popup v-model="showScratch" position="center" class="popsure">
      <div class="sure-title">提示</div>
      <div class="sure-content">
        感谢您的反馈，赠送您100鹿分，和一次抽奖机会。
      </div>
      <div class="sure-sub" @click="jumpScratch()">去抽奖</div>
    </van-popup>
  </div>
</template>

<script>
import { initBack, newAppBack } from "@/lib/appMethod";
import userMixin from "@/mixin/userMixin";
import headBar from "@/components/app/headBar";
import wx from "weixin-js-sdk";
export default {
  components: {
    headBar,
  },
  mixins: [userMixin],
  data() {
    return {
      show: false,
      loading: false,
      finished: false,
      refreshing: false,
      list: [],
      showScratch: false, // 是否弹起刮奖提示

      params: {
        current: 1,
        size: 10,
        userId: "",
      },
      questionTypes: []
    };
  },

  async created() {
    // this.baseURLApp = 'http://192.168.0.119:8777'
    initBack();
    await this.$getUserId();

    this.params.userId = this.userId;

    await this.getQuestionTypes()
    this.getDataList(true);
    // // 是否弹起 刮奖提示  1 弹起， 其他否
    this.showScratch = this.$route.query.isScratch === "1" || false;
  },
  methods: {
    newAppBack,
    getDataList(noRefreshing) {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      this.loading = true;
      this.refreshing = !noRefreshing;
      if (this.refreshing) {
        this.list = []
        this.params.current = 1
        this.finished = false
      }
      this.$axios
        .post(`${this.baseURLApp}/feedback/user/record`, this.params)
        .then((res) => {
          const arr = res.data.records
          this.params.current++
          arr.map((item) => {
            for(let i = 0; i < this.questionTypes.length; i++) {
              let jtem = this.questionTypes[i]
              if (item.feedbackClassify == jtem.id) {
                item.feedbackClassifyStr = jtem.classifyName
                break;
              }
            }
          })
          this.list = this.list.concat(arr)

          this.loading = false;
          this.refreshing = false;
          if (arr.length < 10) {
            this.finished = true;
          }
          this.$toast.clear();
        })
        .catch((r) => {
          this.$toast.clear();
        });
    },
    jumpDetail(v) {
      if (this.appTypeStr === "mini") {
        wx.miniProgram.navigateTo({
          url: `/pages/webView/index?webUrl=${window.location.origin}/#/feedback-record/detail&userId=1&token=1&feedbackId=${v.id}`,
        });
      } else {
        this.$router.push({
          path: "/feedback-record/detail",
          query: { feedbackId: v.id },
        });
      }
    },
    jumpScratch() {
      this.showScratch = false;
      if (this.appTypeStr === "mini") {
        wx.miniProgram.navigateTo({
          url: `/pages/webView/index?webUrl=${window.location.origin}/#/feedback-record/scratch&userId=1&token=1`,
        });
      } else {
        this.$router.push({
          path: "/feedback-record/scratch",
          query: {},
        });
      }
    },
    getQuestionTypes() {
      return this.$axios
        .post(`${this.baseURLApp}/feedback/user/defaultMessage`, {
          userId: this.userId,
          token: this.token,
        })
        .then((res) => {
          this.questionTypes = res.data.classifyVOList;
        });
    },
  },
};
</script>

<style scoped lang="less">
.page-rooter-fff {
  background: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  box-sizing: border-box;
  overflow: hidden;
  .page-refresh {
    flex-grow: 1;
  }
  .list {
    padding: 0 32px;
    //min-height: 60vh;
    .item {
      width: 686px;
      background: #ffffff;
      border-radius: 10px;
      padding: 36px 33px;
      box-sizing: border-box;
      & + .item {
        margin-top: 24px;
      }
      .date-str {
        color: #6c727a;
        font-size: 24px;
      }
      .status {
        &.red {
          color: #f03c18;
        }
      }
      .text {
        margin-top: 30px;
        h3 {
          font-size: 28px;
          font-weight: 400;
          color: #242831;
          flex-shrink: 0;
        }
        p {
          font-size: 24px;
          padding: 0 16px;
          img {
            width: 12px;
            height: 13px;
          }
        }
        h4 {
          font-size: 28px;
          font-weight: 400;
          color: #242831;
        }
      }
    }
  }
  .empty {
    flex-grow: 1;
    padding-top: 150px;
    img {
      width: 300px;
      height: 300px;
    }
    p {
      font-size: 28px;
      color: #6c727a;
    }
  }
}
.popsure {
  width: 590px;
  border-radius: 12px;
  padding: 50px 40px;

  .sure-title {
    color: #242831;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
  }

  .sure-content {
    color: #3c454e;
    font-size: 24px;
    line-height: 1.6;
    margin: 36px 0 48px;
    text-align: center;

    span {
      color: #f03c18;
    }
  }

  .sure-sub {
    border-radius: 8px;
    background: #ffde00;
    color: #000;
    font-weight: bold;
    font-size: 32px;
    line-height: 96px;
    text-align: center;
  }
}
</style>
